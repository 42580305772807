.o-header {
    position: fixed;
    display: flex;
    align-items: center;
    padding-left: rem(24);
    padding-right: rem(24);
    height: rem(80);
    top: 0;
    left: 0;
    width: 100%;
    background: $dark-purple-072;
    backdrop-filter: blur(40px);
    z-index: $z-header;

    @include tablet-down {
        padding-left: rem(16);
        padding-right: rem(16);
    }

    &_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &_logo {
        flex: 1;
        height: rem(48);

        .tfcImage {
            width: rem(139);
            height: 100%;
            object-fit: contain;
            background-size: contain;
        }
    }

    &_hamburger {
        width: rem(32);
        height: rem(32);
        margin-left: rem(16);
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;

        div {
            width: 100%;
            height: rem(4);
            border-radius: rem(2);
            background: $white;
            margin-top: rem(3);
            margin-bottom: rem(3);
            transition: $transition;
        }

        &-active {
            div {
                &:nth-child(1) {
                    transform: rotate(45deg) translate(rem(8), rem(8));
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:nth-child(3) {
                    transform: rotate(-45deg) translate(rem(6), rem(-6));
                }
            }
        }
    }

    &_nav {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.5s;

        @include desktop-down {
            flex: 3;
        }

        @include tablet-down {
            visibility: hidden;
            overflow: hidden;
            height: 0;
            position: fixed;
            top: rem(80);
            left: 0;
            width: 100%;
            display: block;
            padding-left: rem(24);
            padding-right: rem(24);
            z-index: $z-header;
            background: $dark-purple;

            &-active {
                visibility: visible;
                height: calc(100vh - rem(80));
            }
        }
    }

    &_menu {
        flex: 1;
        display: flex;
        justify-content: center;
        @include tablet-down {
            display: block;
            margin-top: rem(60);
        }
    }

    &_navItem {
        color: $white;
        font-size: rem(16);
        font-weight: 600;
        min-width: rem(120);
        margin-left: rem(8);
        margin-right: rem(8);
        text-align: center;
        @include tablet-down {
            display: block;
            text-align: left;
            font-size: rem(22);
            margin-top: rem(24);
        }
    }

    &_controls {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include tablet-down {
            display: block;
            margin-top: rem(80);
        }

        .tfcbtn {
            margin-left: rem(16);
            white-space: nowrap;
            @include tablet-down {
                margin-left: 0;
                width: 100%;
                margin-top: rem(24);
            }
        }

        // &_wrap {
        //     border-radius: 50%;
        //     border: 1px solid $white;
        //     width: rem(44);
        //     height: rem(44);
        //     @include adjust-flex;
        //     cursor: pointer;
        //     margin-left: rem(16);
        // }

        &_language {
            width: 100%;
            max-width: rem(36);
        }
    }

    &_loginBtn {
        margin-left: rem(16);

        @include mobile-down {
            margin-left: 0;
            padding-left: rem(12);
            padding-right: rem(12);
            height: rem(36);

            .tfcText {
                @include font-base(14, 18);
            }
        }
    }
}
