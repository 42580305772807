.o-discoverItem {
    $root: &;
    display: flex;

    @include mobile-down {
        flex-direction: column;
    }

    &_main {
        position: relative;
        flex-shrink: 0;
        max-width: rem(260);
        max-height: rem(260);
        width: var(--discover-height);
        height: var(--discover-height);
        
        @include mobile-down {
            width: 100%;
            max-width: 100%;
            height: auto;
        }

        @include mobile-up {
            min-width: rem(241);
            min-height: rem(241);
        }

        &_content {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;
            padding: rem(16);
            background-color: var(--bg-color);
            border-radius: rem(28);

            
            .tfcImage {
                width: 40%;
                max-width: rem(96);
                align-self: flex-end;
                @include mobile-down {
                    max-width: rem(60);
                }
            }

            &-reverse {
                flex-direction: column-reverse;
                #{$root} {
                    &_name {
                        text-align: right;
                    }
                }
    
                .tfcImage {
                    align-self: flex-start;
                }
            }
        }
    }

    &_name {
        color: var(--content-color);
        width: 100%;
        @include font-base(32, 40);
        font-weight: 900;
    }

    &_content {
        position: relative;
        border-radius: rem(28);
        overflow: hidden;
        margin-left: rem(16);
        padding: rem(16);
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;

        @include tablet-down {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        @include mobile-down {
            margin-left: 0;
            margin-top: rem(16);
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--bg-color);
            opacity: 0.25;
        }

        &_item {
            width: 33.5%;
            display: flex;
            flex-direction: column;

            &-reversed {
                flex-direction: column-reverse;

                @include tablet-down {
                    flex-direction: column;
                }
            }
            @include tablet-down {
                width: 100%;
                margin-top: rem(0);

                &-reversed {
                    margin-top: rem(24);
                }
            }
        }

        &_decor {
            position: absolute;
            height: calc(100% - 32px);
            aspect-ratio: 1;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            filter: blur(8px);
            -webkit-filter: blur(8px);

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: var(--bg-color);
                opacity: 0.02;
                z-index: 2;
                border-radius: rem(16);
            }

            .tfcImage {
                position: relative;
                width: 60%;
                height: 60%;
                z-index: 1;
                opacity: 0.2;
            }
        }
    }

    &_description {
        margin-top: rem(8);
        color: $raisin-black;
        @include font-base(16, 24);
    }

    &_title {
        color: $raisin-black;
        @include font-base(28, 36);
    }

    &-rtl {
        flex-direction: row-reverse;
        @include mobile-down {
            flex-direction: column;
        }
        #{$root} {
            &_main_content {
                .tfcImage {
                    align-self: flex-start;
                }
            }

            &_name {
                text-align: right;
            }

            &_content {
                margin-left: 0;
                margin-right: rem(16);
                flex-direction: row-reverse;
                @include tablet-down {
                    flex-direction: column-reverse;
                    justify-content: flex-end;

                    &_item {
                        margin-top: rem(24);

                        &-reversed {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}
