.o-faqItem {
    padding-top: rem(14);
    padding-bottom: rem(14);
    cursor: pointer;

    &-answer {
        margin-top: rem(30);
        font-weight: 400;
        cursor: auto;

        @include mobile-down {
            margin-top: rem(16);
        }
    }

    &-titleWrapper {
        @include adjust-flex(space-between);
    }

    &-icon {
        margin-right: rem(8);
        transition: transform 0.3s ease;

        &-active {
            transform: rotate(180deg);
        }
    }
}
