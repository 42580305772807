html,
body {
    font-family: $font-sf-pro-display;
    font-size: 16px;
    overflow-x: hidden;
    background-color: $dark-purple;
    @include scroll-bars(4);
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent; //issue click in mobile flash background highligh
}

button {
    &:focus {
        outline: none;
    }
}

p {
    margin-bottom: 0;
    margin-top: 0;
}

a {
    text-decoration: none;
}

[role='button'],
button {
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

input {
    word-break: normal;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-size: inherit;
}

.hidden-animation {
    visibility: hidden;
}

.overflow-hidden {
    height: 100vh;
}

.u-mt {
    &-24 {
        margin-top: rem(24);
    }

    &-16 {
        margin-top: rem(16);
    }
}

.color {
    @include style-color;
}

.fs {
    @include style-font-base;
}

.text {
    @include style-general();
}

.loading-fullscreen {
    height: 100vh;
    left: 0;
    margin: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toast-custom {
    min-width: rem(118);

    .Toastify__toast {
        background: #f8f8f9;
    }

    .Toastify__toast-icon {
        width: rem(24);
    }

    .Toastify__toast-body {
        padding: rem(10) rem(8);

        > div {
            font-weight: 500;
            color: #212024;
            @include font-base(16,20);
        }
    }
}
