.m-userMenu {
    position: relative;

    &_dropdown {
        position: absolute;
        list-style: none;
        background-color: $white;
        width: rem(148);
        border-radius: rem(6);
        box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
        top: rem(52);
        right: 0;
        overflow: hidden;

        &_icon {
            width: rem(20);
            flex-shrink: 0;
            margin-right: rem(6);
            z-index: 1;
            background-size: contain;
        }

        &_item {
            position: relative;
            display: flex;
            align-items: center;
            padding: rem(14) rem(12);
            border-bottom: 1px solid $platinum-08;
            cursor: pointer;
            color: $dark-purple;

            &:last-child {
                border-bottom: 0;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 100%;
                background-color: rgb(255, 255, 255);
                z-index: 1;
                transition: 0.25s;
            }

            &_label {
                z-index: 1;
            }

            &:hover {
                &::before {
                    width: 100%;
                    background-color: rgb(249, 102, 25);
                }

                span {
                    color: $white;
                }
            }
        }
    }

    &_profile {
        width: rem(44);
        height: rem(44);
        background-color: transparent;
        border: 1px solid $white;
        border-radius: 50%;
        position: relative;
        margin-left: rem(16);
        cursor: pointer;

        @include tablet-down {
            width: rem(38);
            height: rem(38);
        }

        &::before {
            position: absolute;
            content: '';
            width: rem(36);
            height: rem(36);
            background-color: #feeee5;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;

            @include tablet-down {
                width: rem(30);
                height: rem(30);
            }
        }

        &_avatar {
            color: $giants-orange;
            font-weight: 600;
            @include font-base(18, 24);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &-premium {
            border-color: $giants-orange;
        }

        &_levelTag {
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: rem(16);
            background: linear-gradient(90deg, $giants-orange 0%, #5c01b6 100%);
            border-radius: rem(16);
            @include adjust-flex;
            @include font-base(8, 11);
            color: $white;
            font-weight: 600;
        }
    }
}
