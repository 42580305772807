.o-container {
    width: 100%;
    max-width: rem(1408);
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(16);
    padding-right: rem(16);

    &-fluid {
        max-width: rem(1060);
    }
}
