.m-customTextArea {
    position: relative;
    border: 1px solid $light-gray;
    border-radius: rem(12);
    overflow: hidden;
    padding: rem(10) rem(16);
    min-height: rem(128);

    &_title {
        margin-bottom: rem(8);
    }

    &_input {
        .tfcInput,
        textarea {
            border: 0;
            padding: 0;
            @include font-base(16, 20);
        }
    }
}
