.p-deleteAccount {
    margin-top: rem(8);

    .o-section {
        padding: rem(32);

        @include mobile-down {
            padding: rem(16);
        }
    }

    &_title {
        font-weight: 700;
        font-size: rem(60);
        line-height: rem(64);
        margin-bottom: rem(24);

        @include mobile-down {
            font-size: rem(40);
            line-height: rem(44);
            margin-bottom: rem(16);
        }
    }

    &_content {
        ul {
            margin-left: rem(32);

            li {
                &::marker {
                    font-size: rem(10);
                }
            }

            @include mobile-down {
                margin-left: rem(24);
            }
        }

        * {
            @include font-base(16, 24);
        }
    }

    &_button {
        margin-left: auto;
        margin-right: auto;
        display: flex !important;
        flex-direction: row;
        gap: rem(12);
    }

    &_textTerms {
        margin-top: rem(12);
        margin-bottom: rem(12);
    }

    .o-section {
        overflow-y: auto;
        border-radius: rem(28);
        @include scroll-bars(4, $sonic-silver);
    }
}
