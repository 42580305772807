.p-login {
    &_form {
        max-width: rem(560);
        margin-left: auto;
        margin-right: auto;
        padding: rem(86) rem(56);
        background-color: $white;
        border-radius: rem(28);

        @include mobile-down {
            padding: rem(24) rem(24);
        }
    }

    &_image {
        max-width: rem(200);
        height: rem(72);
        margin-left: auto;
        margin-right: auto;
    }

    &_title {
        margin-top: rem(32);
        margin-bottom: rem(12);
    }

    &_action {
        padding-left: rem(24);
        padding-right: rem(24);
        /* background: linear-gradient(217.32deg, #fcfcfd -0.01%, rgba(252, 252, 253, 0.6) 100%); */
        background-color: $warm-white-smoke;
        backdrop-filter: blur(32px);
        margin-top: rem(32);
        border-radius: rem(16);
    }

    &_btn {
        width: 100%;
        background-color: transparent;
        flex-direction: row;
        padding: rem(24) 0;
        height: rem(84);
        border-bottom: 1px solid $gainsboro;

        &:last-child {
            border-bottom: 0;
        }

        .a-icon {
            flex-shrink: 0;
        }

        .tfcText {
            flex-grow: 1;
            text-align: left;
            margin-left: rem(16);
        }
    }
}
