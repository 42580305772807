.o-footer {
    margin-top: rem(48);
    margin-bottom: rem(56);

    @include mobile-down {
        margin-top: rem(32);
        margin-bottom: rem(32);
    }

    &_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: rem(16);
        padding-bottom: rem(16);
        border-top: 1px solid $davys-grey-034;
        border-bottom: 1px solid $davys-grey-034;

        @include tablet-down {
            flex-direction: column;
        }
    }

    &_logo {
        width: 100%;
        max-width: rem(140);

        .tfcImage {
            width: 100%;
            height: 100%;
            object-fit: contain;
            background-size: contain;
        }
    }

    &_menu {
        display: flex;
        @include mobile-down {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    &_navItem {
        color: $white;
        font-size: rem(16);
        font-weight: 400;
        min-width: rem(72);
        margin-left: rem(8);
        margin-right: rem(8);
        text-align: center;
        @include tablet-down {
            display: block;
            text-align: left;
            margin-top: rem(24);
        }
    }

    &_socials {
        display: flex;

        @include tablet-down {
            margin-top: rem(16);
        }

        .tfcImage {
            width: rem(24);
            height: rem(24);
        }
    }

    &_social {
        width: rem(40);
        height: rem(40);
        background-color: $platinum-0018;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: rem(20);
        margin-left: rem(16);
    }

    &_hyperlinks {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: rem(16);
        margin-bottom: rem(32);
    }

    &_hyperlink {
        color: $ash-grey;
        font-size: rem(14);
        font-weight: 400;
        min-width: rem(72);
        margin-left: rem(8);
        margin-right: rem(8);
        text-align: center;
        @include tablet-down {
            display: block;
            text-align: left;
            margin-top: rem(24);
        }

        @include mobile-down {
            font-size: rem(12);
            margin-top: rem(16);
        }
    }
}
