.m-customSelect {
    position: relative;
    border: 1px solid $light-gray;
    border-radius: rem(12);
    padding: rem(10) rem(16);

    &_title {
        margin-bottom: rem(8);
    }

    &_chevron {
        top: 50%;
        transform: translateY(-50%);
        right: rem(8);
        position: absolute;
    }

    &_input {
        .tfcInput,
        input {
            cursor: default;
            user-select: none;
            @include font-base(16, 20);
            padding: 0;
            pointer-events: none;
        }
    }

    &_dropdown {
        display: none;
        position: absolute;
        list-style: none;
        background-color: $white;
        width: rem(300);
        border-radius: rem(6);
        // box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
        top: rem(52);
        right: 0;
        overflow: hidden;
        z-index: 100;

        &-show {
            display: block;
        }

        &_item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: rem(10) rem(16);
            border-bottom: 1px solid $platinum-08;
            cursor: pointer;
            color: $dark-purple;

            &:last-child {
                border-bottom: 0;
            }

            &-left {
                display: flex;
                align-items: center;
            }

            &:hover {
                background-color: $eerie-black-005;
            }
        }

        &_icon {
            width: rem(36);
            flex-shrink: 0;
            margin-right: rem(6);
        }

        &_icChecked {
            visibility: hidden;
            opacity: 0;
            flex-shrink: 0;

            &-active {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}
