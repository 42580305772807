.p-helpCenter {
    padding: rem(32);
    margin-bottom: rem(16);

    @include mobile-down {
        padding: rem(16);
    }

    &_title {
        margin-bottom: rem(32);
        @include font-base(60, 64);

        span {
            color: $deep-carrot-orange;
        }

        @include desktop-down {
            @include font-base(42, 60);
        }

        @include break-max(1100px) {
            @include font-base(32, 42);
        }
    }

    &_contact {
        display: flex;
        background-color: $warm-white-smoke;

        @include mobile-down {
            flex-direction: column;
        }

        &_title {
            flex: 1;
            margin-right: rem(16);

            @include mobile-down {
                margin-right: 0;
                margin-bottom: rem(16);
            }
        }

        &_desc {
            a {
                color: $giants-orange;
            }
        }

        &_form {
            background-color: $white;
            padding: rem(16);
            border-radius: rem(16);

            &_wrapper {
                flex: 1;
            }

            &_input {
                &:not(:last-child) {
                    margin-bottom: rem(12);
                }
            }
        }
    }

    &_ctaBtn {
        margin-top: rem(16);
    }

    &.content {
        margin-top: rem(32);

        @include mobile-down {
            margin-top: rem(16);
        }
    }
}

.text-error {
    color: $fire-engine-red;
    margin-left: rem(16);

    &:not(&:last-child) {
        margin-bottom: rem(16);
    }
}
