.o-planCard {
    $root: &;
    position: relative;
    padding: rem(24);
    background-color: $white;
    border-radius: rem(28);
    height: 100%;
    display: flex;
    flex-direction: column;
    border-width: 1px;
    border-top-width: rem(6);
    border-style: solid;
    border-color: var(--cta-color);

    &_header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &-individual {
        background-color: $deep-carrot-orange-01;
    }

    &_title {
        margin-bottom: rem(8);
    }

    &_discount {
        background: $indigo;
        padding: rem(4) rem(8);
        width: fit-content;
        border-radius: rem(8);
        margin-top: rem(4);
        top: rem(-8);
        right: rem(-8);
    }

    &_price {
        color: $giants-orange;
    }

    &_features {
        flex: 1;
        margin-top: rem(16);
        margin-bottom: rem(24);
        list-style: square !important;

        &_item {
            display: flex;
            position: relative;

            &:not(:last-child) {
                margin-bottom: rem(12);
            }

            &_icon {
                margin-right: rem(10);
            }

            .tfcText {
                flex-shrink: 99;
            }

            .tfcText {
                flex-shrink: 99;
            }
        }
    }

    &_ctaBtn {
        &:hover {
            color: var(--cta-color);
            border: 1px solid var(--cta-color) !important;
        }
    }

    &_cta {
        text-transform: capitalize;
    }

    &-active {
        border-color: $forest-green;
        #{$root}_ctaBtn {
            @include adjust-flex;
            flex-direction: row;
            border-radius: rem(100);
            background-color: $honey-dew;
            color: $forest-green;
            pointer-events: none;

            .a-icon {
                display: block;
            }
        }
    }
}

.strikeThrough {
    color: $ash-grey;
    text-decoration: line-through;
}
