/* stylelint-disable selector-no-qualifying-type */
.t-rating {
    margin-top: rem(16);

    &_item {
        padding-left: rem(8);
        padding-right: rem(8);
    }

    .slick {
        &-slider {
            padding-bottom: rem(48);
            padding-left: rem(20);
            padding-right: rem(20);
        }

        &-prev {
            left: rem(-20) !important;
            top: calc(50% - rem(20));
        }

        &-next {
            right: rem(-20) !important;
            top: calc(50% - rem(20));
        }

        &-dots {
            bottom: rem(16);
            transform: translateX(-50%);
            left: 50%;

            li {
                button {
                    background-color: $white;
                    transition: all $transition;
                }
            }

            li.slick-active {
                button {
                    background-color: $deep-carrot-orange;
                }
            } 
        }
    }
}
