/* stylelint-disable declaration-block-no-duplicate-properties */
:root {
    --animate-duration: 1.25s;
    --animate-delay: 1s;
    --animate-repeat: 1;
}

.preanimate {
    opacity: 0;
}

.animate {
    -webkit-animation-duration: 1.25s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: 1.25s;
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

    /* ----------------------------------------------------------------------------------------- */
    /* ----------------------------------- Attention seekers ----------------------------------- */
    /* ----------------------------------------------------------------------------------------- */
    &-bounce {
        animation-name: bounce;
        transform-origin: center bottom;
    }

    &-flash {
        animation-name: flash;
    }

    &-pulse {
        animation-name: pulse;
        animation-timing-function: ease-in-out;
    }

    &-rubberBand {
        animation-name: rubberBand;
    }

    &-shakeX {
        animation-name: shakeX;
    }

    &-shakeY {
        animation-name: shakeY;
    }

    &-swing {
        animation-name: swing;
        transform-origin: top center;
    }

    &-tada {
        animation-name: tada;
    }

    &-wobble {
        animation-name: wobble;
    }

    &-heartBeat {
        animation-duration: calc(var(--animate-duration) * 1.3);
        animation-name: heartBeat;
        animation-timing-function: ease-in-out;
    }

    &-jello {
        animation-name: jello;
        transform-origin: center;
    }

    &-headShake {
        animation-name: headShake;
        animation-timing-function: ease-in-out;
    }

    /* ----------------------------------------------------------------------------------------- */
    /* ----------------------------------- Back entrances -------------------------------------- */
    /* ----------------------------------------------------------------------------------------- */
    &-backInDown {
        animation-name: backInDown;
    }

    &-backInLeft {
        animation-name: backInLeft;
    }

    &-backInRight {
        animation-name: backInRight;
    }

    &-backInUp {
        animation-name: backInUp;
    }

    /* ----------------------------------------------------------------------------------------- */
    /* -------------------------------------- Back exits --------------------------------------- */
    /* ----------------------------------------------------------------------------------------- */
    &-backOutDown {
        animation-name: backOutDown;
    }

    &-backOutLeft {
        animation-name: backOutLeft;
    }

    &-backOutRight {
        animation-name: backOutRight;
    }

    &-backOutUp {
        animation-name: backOutUp;
    }

    /* ----------------------------------------------------------------------------------------- */
    /* ---------------------------------- Bouncing entrances ----------------------------------- */
    /* ----------------------------------------------------------------------------------------- */
    &-bounceIn {
        animation-duration: calc(var(--animate-duration) * 0.75);
        animation-name: bounceIn;
    }

    &-bounceInDown {
        animation-name: bounceInDown;
    }

    &-bounceInLeft {
        animation-name: bounceInLeft;
    }

    &-bounceInRight {
        animation-name: bounceInRight;
    }

    &-bounceInUp {
        animation-name: bounceInUp;
    }

    /* ----------------------------------------------------------------------------------------- */
    /* ---------------------------------- Bouncing exits --------------------------------------- */
    /* ----------------------------------------------------------------------------------------- */
    &-bounceOut {
        animation-duration: calc(var(--animate-duration) * 0.75);
        animation-name: bounceOut;
    }

    &-bounceOutDown {
        animation-name: bounceOutDown;
    }

    &-bounceOutLeft {
        animation-name: bounceOutLeft;
    }

    &-bounceOutRight {
        animation-name: bounceOutRight;
    }

    &-bounceOutUp {
        animation-name: bounceOutUp;
    }

    /* ----------------------------------------------------------------------------------------- */
    /* --------------------------------- Fading entrances -------------------------------------- */
    /* ----------------------------------------------------------------------------------------- */
    &-fadeIn {
        animation-name: fadeIn;
    }

    &-fadeInBottomLeft {
        animation-name: fadeInBottomLeft;
    }

    &-fadeInBottomRight {
        animation-name: fadeInBottomRight;
    }

    &-fadeInDown {
        animation-name: fadeInDown;
    }

    &-fadeInDownBig {
        animation-name: fadeInDownBig;
    }

    &-fadeInLeft {
        animation-name: fadeInLeft;
    }

    &-fadeInLeftBig {
        animation-name: fadeInLeftBig;
    }

    &-fadeInRight {
        animation-name: fadeInRight;
    }

    &-fadeInRightBig {
        animation-name: fadeInRightBig;
    }

    &-fadeInTopLeft {
        animation-name: fadeInTopLeft;
    }

    &-fadeInTopRight {
        animation-name: fadeInTopRight;
    }

    &-fadeInUp {
        animation-name: fadeInUp;
    }

    &-fadeInUpBig {
        animation-name: fadeInUpBig;
    }

    /* ----------------------------------------------------------------------------------------- */
    /* ------------------------------------ Fading exits --------------------------------------- */
    /* ----------------------------------------------------------------------------------------- */
    &-fadeOut {
        animation-name: fadeOut;
    }

    &-fadeOutBottomLeft {
        animation-name: fadeOutBottomLeft;
    }

    &-fadeOutBottomRight {
        animation-name: fadeOutBottomRight;
    }

    &-fadeOutDown {
        animation-name: fadeOutDown;
    }

    &-fadeOutDownBig {
        animation-name: fadeOutDownBig;
    }

    &-fadeOutLeft {
        animation-name: fadeOutLeft;
    }

    &-fadeOutLeftBig {
        animation-name: fadeOutLeftBig;
    }

    &-fadeOutRight {
        animation-name: fadeOutRight;
    }

    &-fadeOutRightBig {
        animation-name: fadeOutRightBig;
    }

    &-fadeOutTopLeft {
        animation-name: fadeOutTopLeft;
    }

    &-fadeOutTopRight {
        animation-name: fadeOutTopRight;
    }

    &-fadeOutUp {
        animation-name: fadeOutUp;
    }

    &-fadeOutUpBig {
        animation-name: fadeOutUpBig;
    }

    /* ----------------------------------------------------------------------------------------- */
    /* ---------------------------------------- Flippers --------------------------------------- */
    /* ----------------------------------------------------------------------------------------- */
    &-flip {
        animation-name: flip;
        backface-visibility: visible;
    }

    &-flipInX {
        animation-name: flipInX;
        backface-visibility: visible;
    }

    &-flipInY {
        animation-name: flipInY;
        backface-visibility: visible;
    }

    &-flipOutX {
        animation-duration: calc(var(--animate-duration) * 0.75);
        animation-name: flipOutX;
        backface-visibility: visible;
    }

    &-flipOutY {
        animation-duration: calc(var(--animate-duration) * 0.75);
        animation-name: flipOutY;
        backface-visibility: visible;
    }

    /* ----------------------------------------------------------------------------------------- */
    /* -------------------------------------- Lightspeed --------------------------------------- */
    /* ----------------------------------------------------------------------------------------- */
    &-lightSpeedInLeft {
        animation-name: lightSpeedInLeft;
        animation-timing-function: ease-out;
    }

    &-lightSpeedInRight {
        animation-name: lightSpeedInRight;
        animation-timing-function: ease-out;
    }

    &-lightSpeedOutLeft {
        animation-name: lightSpeedOutLeft;
        animation-timing-function: ease-in;
    }

    &-lightSpeedOutRight {
        animation-name: lightSpeedOutRight;
        animation-timing-function: ease-in;
    }

    /* ----------------------------------------------------------------------------------------- */
    /* --------------------------------- Rotating entrances ------------------------------------ */
    /* ----------------------------------------------------------------------------------------- */
    &-rotateIn {
        animation-name: rotateIn;
        transform-origin: center;
    }

    &-rotateInDownLeft {
        animation-name: rotateInDownLeft;
        transform-origin: left bottom;
    }

    &-rotateInDownRight {
        animation-name: rotateInPosition;
        transform-origin: right bottom;
    }

    &-rotateInUpLeft {
        animation-name: rotateInPosition;
        transform-origin: left bottom;
    }

    &-rotateInUpRight {
        animation-name: rotateInUpRight;
        transform-origin: right bottom;
    }

    /* ----------------------------------------------------------------------------------------- */
    /* ------------------------------------ Rotating exits ------------------------------------- */
    /* ----------------------------------------------------------------------------------------- */
    &-rotateOut {
        animation-name: rotateOut;
        transform-origin: center;
    }

    &-rotateOutDownLeft {
        animation-name: rotateOutPositive;
        transform-origin: left bottom;
    }

    &-rotateOutDownRight {
        animation-name: rotateOutNegative;
        transform-origin: right bottom;
    }

    &-rotateOutUpLeft {
        animation-name: rotateOutNegative;
        transform-origin: left bottom;
    }

    &-rotateOutUpRight {
        animation-name: rotateOutPositive;
        transform-origin: right bottom;
    }

    /* ----------------------------------------------------------------------------------------- */
    /* ---------------------------------------- Specials --------------------------------------- */
    /* ----------------------------------------------------------------------------------------- */
    &-hinge {
        animation-duration: calc(var(--animate-duration) * 2);
        animation-name: hinge;
        transform-origin: top left;
    }

    &-jackInTheBox {
        animation-name: jackInTheBox;
    }

    &-rollIn {
        animation-name: rollIn;
    }

    &-rollOut {
        animation-name: rollOut;
    }

    /* ----------------------------------------------------------------------------------------- */
    /* ---------------------------------- Zooming entrances ------------------------------------ */
    /* ----------------------------------------------------------------------------------------- */
    &-zoomIn {
        animation-name: zoomIn;
    }

    &-zoomInDown {
        animation-name: zoomInDown;
    }

    &-zoomInLeft {
        animation-name: zoomInLeft;
    }

    &-zoomInRight {
        animation-name: zoomInRight;
    }

    &-zoomInUp {
        animation-name: zoomInUp;
    }

    /* ----------------------------------------------------------------------------------------- */
    /* -------------------------------------- Zooming exits ------------------------------------ */
    /* ----------------------------------------------------------------------------------------- */
    &-zoomOut {
        animation-name: zoomOut;
    }

    &-zoomOutDown {
        animation-name: zoomOutDown;
        transform-origin: center bottom;
    }

    &-zoomOutLeft {
        animation-name: zoomOutLeft;
        transform-origin: left center;
    }

    &-zoomOutRight {
        animation-name: zoomOutRight;
        transform-origin: right center;
    }

    &-zoomOutUp {
        animation-name: zoomOutUp;
        transform-origin: center bottom;
    }

    /* ----------------------------------------------------------------------------------------- */
    /* ------------------------------------ Sliding entrances ---------------------------------- */
    /* ----------------------------------------------------------------------------------------- */
    &-slideInDown {
        animation-name: slideInDown;
    }

    &-slideInLeft {
        animation-name: slideInLeft;
    }

    &-slideInRight {
        animation-name: slideInRight;
    }

    &-slideInUp {
        animation-name: slideInUp;
    }

    /* ----------------------------------------------------------------------------------------- */
    /* -------------------------------------- Sliding exits ------------------------------------ */
    /* ----------------------------------------------------------------------------------------- */
    &-slideOutDown {
        animation-name: slideOutDown;
    }

    &-slideOutLeft {
        animation-name: slideOutLeft;
    }

    &-slideOutRight {
        animation-name: slideOutRight;
    }

    &-slideOutUp {
        animation-name: slideOutUp;
    }
}

@keyframes tail {
    to {
        transform: rotate(1turn);
    }
}
