.p-plan {
    $root: &;
    margin-top: rem(16);

    &_container {
        border-radius: rem(32);
    }

    &_content {
        display: flex;
        position: relative;

        &-column {
            flex-direction: column;
            padding: rem(16);
            @include mobile-down {
                padding: rem(8);
            }
            #{$root} {
                &_left {
                    max-width: 100%;
                    margin-bottom: rem(32);
                    margin-right: 0;
                    @include mobile-down {
                        margin-bottom: rem(16);
                    }
                }

                &_center,
                &_right {
                    flex: 1;
                    max-width: 100%;
                }

                &_center {
                    margin-right: rem(32);
                }
            }
        }

        @include tablet-down {
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &_left {
        margin-right: rem(16);
        max-width: rem(424);
        flex-shrink: 0;

        @include desktop-down {
            max-width: rem(300);
        }

        @include tablet-down {
            max-width: 100%;
            margin-right: 0;
            margin-bottom: rem(16);
        }
    }

    &_options {
        display: flex;
        width: 100%;
        margin-bottom: rem(32);

        @include mobile-down {
            flex-direction: column;
            margin-bottom: rem(24);
        }
    }

    &_center {
        margin-right: rem(16);
        max-width: rem(460);
        width: 100%;

        @include tablet-down {
            max-width: unset;
        }

        @include mobile-down {
            margin-bottom: rem(16);
        }
    }

    &_right {
        max-width: rem(460);
        width: 100%;

        @include tablet-down {
            max-width: unset;
        }
    }

    &_item {
        width: 100%;

        &:not(:last-child) {
            margin-right: rem(32);
        }

        @include tablet-down {
            max-width: unset;
        }

        @include mobile-down {
            &:not(:last-child) {
                margin-bottom: rem(24);
            }
        }

        &-display {
            &:not(:last-child) {
                margin-right: rem(16);
            }

            @include mobile-down {
                &:not(:last-child) {
                    margin-bottom: rem(16);
                }
            }
        }
    }

    &_title {
        @include font-base(60, 64);
        font-weight: 700;

        span {
            color: $deep-carrot-orange;
        }

        @include desktop-down {
            @include font-base(42, 48);
        }

        @include break-max(1100px) {
            @include font-base(32, 38);
        }
    }

    &_description {
        @include font-base(16, 24);
        margin-top: rem(16);
        color: $raisin-black;
        font-weight: 400;
    }

    &_activationCode {
        border-radius: rem(16);
        border-width: 1px;
        border-top: rem(6);
        border-style: solid;
        border-color: $gainsboro;
        padding: rem(16);
    }

    &_input {
        .tfcInput {
            background-color: transparent;
            padding: 0;

            input {
                padding: 0;
                @include font-base(16, 20);
                font-weight: 500;
                height: rem(23);
                margin-top: rem(4);

                &::placeholder {
                    color: $ash-grey;
                }
            }
        }
    }
}
