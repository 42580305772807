/* stylelint-disable selector-no-qualifying-type */
.m-carousel {
    $root: &;
    position: relative;

    &_arrow {
        $arrow: &;
        background: transparent;
        border-radius: rem(8);
        height: rem(36);
        width: rem(36);
        z-index: $z-1;
        padding: rem(10);
        transition: all $transition ease-in-out;

        &::before {
            background-size: 100% 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: '';
            display: block;
            height: rem(32);
            width: rem(32);
        }

        &_prev {
            cursor: pointer;

            &-defaultArrow {
                left: rem(36);
                transition: all 0.3s ease-in-out;

                &::before {
                    background-image: url('~assets/images/arrows-left.svg');
                }

                &:hover {
                    &::before {
                        background-image: url('~assets/images/arrows-left.svg');
                    }
                }
            }

        }

        &_next {
            cursor: pointer;

            &-defaultArrow {
                right: rem(36);
                /* background-color: $white; */
                transition: all 0.3s ease-in-out;

                &::before {
                    background-image: url('~assets/images/arrows-right.svg');
                }

                &:hover {
                    /* background-color: $french-plum; */

                    &::before {
                        background-image: url('~assets/images/arrows-right.svg');
                    }
                }
            }
        }
    }

    .slick-dots {
        font-size: initial;
    
        li {
            width: rem(8);
            height: rem(8);
            margin-left: rem(4);
            margin-right: rem(4);
    
            button {
                padding: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: rgba($black, 0.3);
    
                &::before {
                    content: none;
                }
            }
    
            &.slick-active {
                width: rem(20);
    
                button {
                    border-radius: rem(16);
                    background-color: $white;
                }
            }
        }
    }

    &-magentaDots {
        li.slick-active {
            >button {
                /* background-color: $magenta !important; */
            }
        }
    }

    &-homeNews {
        .m-carousel_arrow {
            transform: translateY(calc(-50% - 48px));

            @include mobile-down {
                transform: translateY(calc(-50% - 42px));
            }
        }
    }

    &-banner {
        height: 100%;

        .slick-slider,
        .slick-list,
        .slick-track {
            height: 100%;
        }

        .slick-slide {
            div {
                height: 100%;
            }
        }
    }
}

.slick-prev {
    left: rem(10) !important;
}

.slick-next {
    right: rem(10) !important;
}

.slick-prev,
.slick-next {
    font-size: 15px !important;
}

.slick-prev::before,
.slick-next::before {
    content: '' !important;
}
