.t-faqList {
    &_title {
        color: $anti-flash-white-2;
        font-weight: 700;
        font-size: rem(60);
        line-height: rem(64);
        margin-bottom: rem(24);
        margin-top: rem(48);


        @include mobile-down {
            font-size: rem(40);
            line-height: rem(44);
            margin-bottom: rem(16);
        }
    }

    .o-faqItem {
        box-shadow: 0 -1px 0  $lavender-gray;

        &:last-child {
            border-bottom: 1px solid $lavender-gray;
        }
    }
}
