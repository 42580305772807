.p-introduction {
    margin-top: rem(16);

    &_content {
        display: flex;
        position: relative;
        @include tablet-down {
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &_left {
        position: relative;
        display: flex;
        align-items: flex-end;
        width: 25%;
        flex-shrink: 0;
        z-index: 2;
        @include tablet-down {
            order: 1;
            width: 40%;
        }

        .tfcImage {
            border-radius: rem(16);
        }
    }

    &_center {
        position: relative;
        flex-grow: 1;
        padding-left: rem(32);
        padding-right: rem(32);
        z-index: 2;
        @include tablet-down {
            order: 0;
            width: 100%;
            padding-left: rem(16);
            padding-right: rem(16);
        }
        @include mobile-down {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &_right {
        position: relative;
        display: flex;
        align-items: flex-end;
        width: 30%;
        flex-shrink: 0;
        z-index: 1;
        @include tablet-down {
            order: 2;
            width: 40%;
        }
    }

    &_getnow {
        position: absolute;
        z-index: 1;
        width: 100%;
        bottom: rem(-16);
        right: rem(-16);
        display: flex;
        justify-content: flex-end;
        background: $white-016;
        backdrop-filter: blur(12px);
        padding: rem(40) rem(80);

        @include tablet-down {
            padding: rem(24) rem(60);
        }

        @include mobile-down {
            position: relative;
            order: 3;
            width: 100%;
            justify-content: center;
            padding: rem(24) rem(16);
            bottom: unset;
            right: unset;
        }

        &_content {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &_title {
            @include font-base(18,22);
            font-weight: 500;
            color: $jet;
            margin-bottom: rem(8);
        }

        &_description {
            @include font-base(16,20);
            color: $jet;
            font-weight: 500;
            margin-bottom: rem(16);
        }
    }

    &_title {
        @include font-base(60,64);
        font-weight: 700;
        color: $dark-purple;

        span {
            color: $deep-carrot-orange;
        }

        @include desktop-down {
            @include font-base(42,48);
        }

        @include break-max(1100px) {
            @include font-base(32,38);
        }
    }

    &_description {
        @include font-base(16,24);
        margin-top: rem(16);
        color: $raisin-black;
        font-weight: 400;
    }

    &_getWrap {
        display: flex;
        align-items: center;
        margin-top: rem(56);

        @include desktop-down {
            margin-top: rem(32);
            margin-bottom: rem(24);
        }
    }

    &_getit {
        @include tablet-down {
            justify-content: center;
        }

        &_item {
            &:first-child {
                margin-bottom: rem(16);
            }
        }

        &_label {
            display: flex;
            align-items: center;
            padding: rem(11) rem(9);
            background-color: $anti-flash-white;
            border-radius: rem(16);
            width: 100%;

            .tfcImage {
                width: rem(48);
                margin-right: rem(8);
                @include mobile-down {
                    width: rem(32);
                }
            }
        }

        &_qr {
            width: rem(150);
            height: rem(150);
            margin-left: rem(56);

            @include desktop-down {
                margin-left: rem(24);
            }

            .tfcImage {
                width: 100%;
            }
        }

        &_text {
            color: $dark-purple;
            @include font-base(16, 20);
            font-weight: 500;
            
            span {
                font-weight: 700;
                @include font-base(18, 24);
                text-transform: uppercase;
            }
            @include mobile-down {
                @include font-base(12,16);

                span {
                    @include font-base(14,21);
                }
            }
        }
    }
}
