.o-ratingCard {
    background-color: $white;
    border-radius: rem(28);
    padding: rem(16);

    &_rate {
        display: flex;
    }

    &_star {
        width: rem(24);
        height: rem(24);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin-right: rem(8);

        &-full {
            background-image: url('~assets/images/rating/star.svg');
        }

        &-haft {
            background-image: url('~assets/images/rating/star_haft.svg');
        }

        &-none {
            background-image: url('~assets/images/rating/star_none.svg');
        }
    }

    &_text {
        margin-top: rem(20);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    }

    &_name {
        margin-top: rem(20);
    }
}
