.t-mainLayout {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    &_body {
        padding-top: rem(88);
        flex-grow: 1;
    }
}
