.m-language {
    position: relative;

    &_display {
        $display: &;
        @include adjust-flex;
        cursor: pointer;
        margin-left: rem(16);

        &_icon {
            width: rem(36);
            flex-shrink: 0;
            margin-right: rem(6);
        }

        &_label {
            color: $white;
        }

        &_arrow {
            background-image: url('~assets/icons/ic_arrow-down.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: rem(14);
            height: rem(24);
            margin-left: rem(8);
            flex-shrink: 0;
            transition: all 0.25s ease-in-out;
        }

        &-activeDropdown {
            #{$display}_arrow {
                transform: rotate(-180deg);
            }
        }
    }

    &_dropdown {
        display: none;
        position: absolute;
        list-style: none;
        background-color: $white;
        width: rem(180);
        border-radius: rem(6);
        // box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
        top: rem(52);
        right: 0;
        overflow: hidden;

        &-show {
            display: block;;
        }

        &_item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: rem(8) rem(12);
            border-bottom: 1px solid $platinum-08;
            cursor: pointer;
            color: $dark-purple;

            &:last-child {
                border-bottom: 0;
            }

            &-left {
                display: flex;
                align-items: center;
            }

            &:hover {
                background-color: $eerie-black-005;
            }
        }

        &_icon {
            width: rem(36);
            flex-shrink: 0;
            margin-right: rem(6);
        }

        &_icChecked {
            visibility: hidden;
            opacity: 0;
            flex-shrink: 0;

            &-active {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}
