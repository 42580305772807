.tabs {
    display: grid;
    grid-row: 3px 1fr;

    &_container {
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &_list {
        list-style: none;
        padding: 0;
        margin: 0;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
    }

    &_line {
        width: 100%;
        height: rem(1);
        background-color: $lavender-gray;
    }

    .tab {
        display: inline-block;
        padding: rem(8);
        padding-top: 0;
        cursor: pointer;
        margin-right: rem(8);
        white-space: nowrap;

        &_content {
            display: none;

            &-main-active {
                position: relative;
                display: block;
                z-index: 1;
            }

            &-sub-active {
                display: block;
                position: absolute;
                top: 0;
            }

            &-slide-in {
                &-left {
                    animation: slideInLeft 0.5s cubic-bezier(0.55, 0, 0, 1);
                }

                &-right {
                    animation: slideInRight 0.5s cubic-bezier(0.55, 0, 0, 1);
                }
            }

            &-slide-out {
                &-left {
                    left: 0;
                    transform: translateX(-100%);
                    animation: slideOutLeft 0.5s cubic-bezier(0.55, 0, 0, 1);
                    opacity: 0;
                }

                &-right {
                    width: 100%;
                    animation: slideOutRight 0.5s cubic-bezier(0.55, 0, 0, 1);
                    opacity: 0;
                }
            }
        }

        &_text {
            color: $sonic-silver;
            transition: 0.25s color ease;

            &-active {
                color: $giants-orange;
            }
        }
    }

    &_content {
        width: 100%;
        position: relative;
    }

    &_indicator {
        height: 3px;
        background: $giants-orange;
        transition: all 0.25s cubic-bezier(0.15, 0.3, 0.25, 1);
        border-radius: 8px;
        overflow: hidden;
    }
}

@keyframes slideInLeft {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOutLeft {
    from {
        transform: translateX(0);
        opacity: 1;
    }

    to {
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes slideInRight {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOutRight {
    from {
        transform: translateX(0);
        opacity: 1;
    }

    to {
        transform: translateX(100%);
        opacity: 0;
    }
}
