// Text Colors
$white: rgb(255, 255, 255); // #ffffff
$black: rgb(0, 0, 0); // #000000
$fire-engine-red: rgb(198, 30, 30); //#c61e1e
$white-smoke: rgb(244, 246, 250); //#f4f6fa
$davys-grey: rgb(89, 89, 89); //#595959
$platinum: rgb(222, 224, 234); // #dee0ea
$isabelline: rgb(237, 237, 237); //#ededed
$dark-purple: rgb(28, 4, 55); //#1c0437
$deep-carrot-orange: rgb(231, 111, 52); //#e76f34
$sonic-silver: rgb(117, 117, 117); //#757575
$anti-flash-white: rgb(242, 242, 247); //#f2f2f7
$jet: rgb(48, 48, 48); //#303030
$magnolia: rgb(244, 236, 254); //#f4ecfe
$electric-indigo: rgb(114, 20, 243); //#7214f3
$platinum: rgba(231, 224, 236); //#e7e0ec
$ash-grey: rgb(179, 179, 179); //#b3b3b3
$anti-flash-white-2: rgb(243, 243, 243); //#f3f3f3
$seashell: rgb(253, 241, 235); //#fdf1eb
$smoky-black: rgb(16, 16, 16); //#101010
$gainsboro: rgb(217, 217, 217); //#d9d9d9
$arsenic: rgb(67, 67, 67); //#434343
$safety-orange: rgb(249, 120, 0); //#f97800
$lavender-gray: rgb(198, 198, 200); //#c6c6c8
$lime-green: rgb(36, 193, 45); //#24c12d
$forest-green: rgb(25, 159, 31); //#199f1f
$dim-gray: rgb(111, 111, 111); //#6f6f6f
$eerie-black: rgb(30, 30, 30); //#1e1e1e
$eerie-black2: rgb(29, 27, 32); // #1d1b20
$giants-orange: rgb(249, 102, 25); //#f96619
$deep-violet: rgba(48, 0, 95); //#30005F
$indigo: rgba(66, 1, 132); //#420184
$warm-white-smoke: rgba(248, 248, 249); //#f8f8f9
$raisin-black: rgba(33, 32, 36); //#212024
$platinum: rgba(233, 233, 233); //#e9e9e9
$honey-dew: rgb(233, 250, 232); //#e9fae8
$light-gray: rgb(213, 212, 216); //#d5d4d8

// Overlays
$dark-purple-072: rgba(28, 4, 55, 0.72); //#1c0437b8
$white-016: rgba(255, 255, 255, 0.16); //#ffffff29
$deep-carrot-orange-01: rgba(231, 111, 52, 0.1); //#E76F341A
$platinum-0018: rgba(231, 224, 236, 0.08); //#e7e0ec14
$platinum-08: rgba(231, 224, 236, 0.8); //#e7e0ec14
$davys-grey-034: rgba(89, 89, 89, 0.34); //#59595956
$eerie-black-06: rgba($eerie-black2, 0.6);
$eerie-black-005: rgba($eerie-black2, 0.05);

// Linears
