.t-discover {
    margin-top: rem(16);

    &_title {
        @include font-base(60,64);
        font-weight: 800;
        color: $dark-purple;

        span {
            color: $deep-carrot-orange;
        }

        @include desktop-down {
            @include font-base(42,60);
        }

        @include break-max(1100px) {
            @include font-base(32,42);
        }
    }

    &_item {
        margin-top: rem(16);
    }
}
