.o-section {
    padding: rem(16);
    border-radius: rem(28);
    overflow: hidden;
    background-color: $white;

    &-noBg {
        background-color: transparent;
    }
}
