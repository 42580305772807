.t-contributes {
    margin-top: rem(16);

    &_image {
        max-width: rem(160);
        margin-left: auto;
        margin-right: auto;
        background-position: center;
        background-size: contain;

        img {
            display: none;
        }
    }
}
