@use 'sass:math';

@mixin text-overflow ($number: 2) {
    display: -webkit-box;
    -webkit-line-clamp: ($number);
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin aspect-ratio($width: 1, $height: 1) {
    position: relative;

    &::before {
        content: '';
        display: block;
        padding-bottom: math.div($height, $width) * 100%;
    }
}

@mixin transition($value) {
    transition: $value;
}

@mixin scroll-bars($size: 12, $thumb-color: $anti-flash-white, $track-color: transparent, $border-radius-thumb: 5, $border-thumb: 2) {

    // For Internet Explorer

    scrollbar-face-color: $thumb-color;
    scrollbar-track-color: $track-color;

    // For Google Chrome
    &::-webkit-scrollbar {
        height: rem($size);
        margin-right: rem(10);
        width: rem($size);
    }

    &::-webkit-scrollbar-thumb {
        background: $thumb-color;
        border: rem($border-thumb) solid $track-color;
        border-radius: rem($border-radius-thumb);
    }

    &::-webkit-scrollbar-track {
        background: $track-color;
    }
}

@mixin font-base($font-size: 16, $line-height: 24) {
    font-size: rem($font-size);
    line-height: rem($line-height);
}

@mixin style-bg-color() {
    &-bg-white {
        background-color: $white;
    }
}

@mixin style-font-weight() {
    &-100 {
        font-weight: 100;
    }

    &-200 {
        font-weight: 200;
    }

    &-300 {
        font-weight: 300;
    }

    &-400 {
        font-weight: normal;
    }

    &-500 {
        font-weight: 500;
    }

    &-600 {
        font-weight: 600;
    }

    &-700 {
        font-weight: bold;
    }

    &-800 {
        font-weight: 800;
    }

    &-900 {
        font-weight: 900;
    }
}

@mixin style-general() {
    &-uppercase {
        text-transform: uppercase;
    }

    &-capitalize {
        text-transform: capitalize;
    }

    &-underline {
        text-decoration: underline;
    }

    &-italic {
        font-style: italic;
    }

    &-center {
        text-align: center;
    }

    &-justify {
        text-align: justify;
    }
}

@mixin reset-button {
    appearance: none;
    background-color: none;
    border: 0;
    outline: none;
}

@mixin list-style-none {
    list-style: none;
    margin: 0;
    padding: 0;
}

@mixin adjust-flex($justify-content: center, $align-item: center) {
    align-items: $align-item;
    display: flex;
    justify-content: $justify-content;
}

$colors-design: (
    'white': $white,
    'black': $black,
    'deep-carrot-orange': $deep-carrot-orange,
    'electric-indigo': $electric-indigo,
    'sonic-silver': $sonic-silver,
    'antiFlashWhite2': $anti-flash-white-2,
    'jet': $jet,
    'smoky-black': $smoky-black,
    'gainsboro': $gainsboro,
    'arsenic': $arsenic,
    'dim-gray': $dim-gray,
    'eerie-black': $eerie-black,
    'deep-violet': $deep-violet,
    'indigo': $indigo,
    'giants-orange': $giants-orange,
    'ash-grey': $ash-grey,
);

@mixin style-color {
    @each $name, $color in $colors-design {
        &-#{$name} {
            color: $color;
        }
    }
}
@mixin style-font-base() {
    &-14x16 {
        @include font-base(14, 16);

        @include mobile-down {
            @include font-base(12, 14);
        }
    }

    &-14x21 {
        @include font-base(14, 21);

        @include mobile-down {
            @include font-base(12, 16);
        }
    }

    &-15x22 {
        @include font-base(15, 22);

        @include mobile-down {
            @include font-base(12, 16);
        }
    }

    &-16x18 {
        @include font-base(16, 18);

        @include mobile-down {
            @include font-base(14, 16);
        }
    }

    &-16x20 {
        @include font-base(16, 20);

        @include mobile-down {
            @include font-base(14, 16);
        }
    }

    &-16x24 {
        @include font-base(16, 24);

        @include mobile-down {
            @include font-base(14, 18);
        }
    }

    &-18x24 {
        @include font-base(18, 24);
        @include mobile-down {
            @include font-base(16, 22);
        }
    }

    &-18x27 {
        @include font-base(18, 27);
        @include mobile-down {
            @include font-base(16, 24);
        }
    }

    &-20x30 {
        @include font-base(20, 30);
        @include mobile-down {
            @include font-base(16, 24);
        }
    }

    &-22x30 {
        @include font-base(22, 30);
        @include mobile-down {
            @include font-base(18, 24);
        }
    }

    &-24x36 {
        @include font-base(24, 36);

        @include tablet-down {
            @include font-base(22, 28);
        }
        @include mobile-down {
            @include font-base(20, 26);
        }
    }

    &-28x42 {
        @include font-base(28, 42);

        @include tablet-down {
            @include font-base(24, 32);
        }
        @include mobile-down {
            @include font-base(22, 28);
        }
    }

    &-32x48 {
        @include font-base(32, 48);

        @include mobile-down {
            @include font-base(24, 32);
        }
    }

    &-60x64 {
        @include font-base(60, 64);

        @include mobile-down {
            @include font-base(42, 48);
        }
    }
}
