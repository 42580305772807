.p-plan {
    &_faq {
        &_wrapper {
            padding-left: rem(32);
            padding-right: rem(32);

            @include mobile-down {
                margin-top: rem(24);
                padding-left: rem(16);
                padding-right: rem(16);
            }
        }
    }
}
