.btn {
    &-h44 {
        height: rem(44);
        border-radius: rem(22);
        font-size: rem(16);
        font-weight: 600;
        padding-left: rem(24);
        padding-right: rem(24);
        overflow: hidden;
    }

    &-h52 {
        height: rem(52);
        border-radius: rem(12);
        font-size: rem(16);
        font-weight: 500;
        padding: rem(14) rem(16);
        overflow: hidden;
    }

    &-primary {
        position: relative;
        background-color: $white;
        color: $dark-purple;
        transition: $transition;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            background-color: $deep-carrot-orange;
            z-index: 1;
            transition: $transition;
        }

        .tfcText {
            position: relative;
            z-index: 2;
        }

        &:hover {
            color: $white;

            &::before {
                width: 100%;
            }
        }
    }

    &-outline {
        position: relative;
        background-color: transparent;
        color: $white;
        border: 1px solid $white;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            background-color: $white;
            z-index: 1;
            transition: $transition;
        }

        .tfcText {
            position: relative;
            z-index: 2;
        }

        &:hover {
            color: $dark-purple;
            border-color: $dark-purple;

            &::before {
                width: 100%;
            }
        }
    }

    &-secondary {
        position: relative;
        background-color: $giants-orange;
        color: $white;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            background-color: $white;
            z-index: 1;
            transition: $transition;
        }

        .tfcText {
            position: relative;
            z-index: 2;
        }

        &:hover {
            color: $giants-orange;
            border: 1px solid $giants-orange;

            &::before {
                width: 100%;
            }
        }
    }

    &-tertiary {
        position: relative;
        background-color: $giants-orange;
        color: $white;
        border: 0;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            background-color: $white;
            z-index: 1;
            transition: $transition;
        }

        .tfcText {
            position: relative;
            z-index: 2;
        }

        &:hover {
            color: $electric-indigo;
            border: 1px solid $electric-indigo;

            &::before {
                width: 100%;
            }
        }
    }

    &-roundedFull {
        border-radius: rem(100);
    }

    &-wfull {
        width: 100%;
    }
}
