.o-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100svh;
    z-index: 9999;
    opacity: 0;
    padding: 0 rem(16);
    animation: fadeIn 0.3s ease-in-out forwards;

    &_overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 0;
        opacity: 0;
        animation: fadeIn 0.3s ease-in-out forwards;
    }

    &_wrapper {
        position: relative;
        max-width: rem(460);
        width: 100%;
        height: rem(300);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        border-radius: rem(24);
        padding: rem(24);
        background-color: #fff;
        z-index: 1;
        animation: slideIn 0.3s ease-in-out forwards;
        display: flex;
        flex-direction: column;

        /* @include tablet-down {
      left: 0;
      transform: translate(0%, -50%);
    } */
    }

    &_header {
        margin-bottom: rem(12);
    }

    &_body {
        flex: 1;
    }

    &_footer {
        width: 100%;
        display: flex;
    }

    &-closing {
        animation: fadeOut 0.3s ease-in-out forwards;

        .o-modal_overlay {
            animation: fadeOut 0.3s ease-in-out forwards;
        }

        .o-modal_wrapper {
            animation: slideOut 0.3s ease-in-out forwards;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        backdrop-filter: blur(0);
    }

    to {
        opacity: 1;
        backdrop-filter: blur(40px);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        backdrop-filter: blur(40px);
    }

    to {
        opacity: 0;
        backdrop-filter: blur(0);
    }
}

@keyframes slideIn {
    from {
        transform: translate(-50%, -60%);
        opacity: 0;
    }

    to {
        transform: translate(-50%, -50%);
        opacity: 1;
    }
}

@keyframes slideOut {
    from {
        transform: translate(-50%, -50%);
        opacity: 1;
    }

    to {
        transform: translate(-50%, -60%);
        opacity: 0;
    }
}
