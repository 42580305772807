.a-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;

    &-16 {
        height: rem(16);
        width: rem(16);
    }

    &-18 {
        height: rem(18);
        width: rem(18);
    }

    &-20 {
        height: rem(20);
        width: rem(20);
    }

    &-24 {
        height: rem(24);
        width: rem(24);
    }

    &-26 {
        height: rem(26);
        width: rem(26);
    }

    &-28 {
        height: rem(28);
        width: rem(28);
    }

    &-30 {
        height: rem(30);
        width: rem(30);
    }

    &-32 {
        height: rem(32);
        width: rem(32);
    }

    &-36 {
        height: rem(36);
        width: rem(36);
    }

    &-64 {
        height: rem(64);
        width: rem(64);
    }

    &-full {
        width: 100%;
        aspect-ratio: 1;
    }

    &-tickOrange {
        background-image: url('~assets/icons/ic_tick-orange.svg');
    }

    &-tickIndigo {
        background-image: url('~assets/icons/ic_tick-indigo.svg');
    }

    &-momo {
        background-image: url('~assets/icons/ic_momo.svg');
    }

    &-visa {
        background-image: url('~assets/icons/ic_visa.svg');
    }

    &-paypal {
        background-image: url('~assets/icons/ic_paypal.svg');
    }

    &-arrowDown {
        background-image: url('~assets/icons/ic_arrow-down.svg');
    }

    &-protect {
        background-image: url('~assets/icons/ic_protect.svg');
    }

    &-google {
        background-image: url('~assets/icons/ic_google.svg');
    }

    &-facebook {
        background-image: url('~assets/icons/ic_facebook.svg');
    }

    &-apple {
        background-image: url('~assets/icons/ic_apple.svg');
    }

    &-arrowRight {
        background-image: url('~assets/icons/ic_arrow_right.svg');
    }

    &-user {
        background-image: url('~assets/icons/ic_user.svg');
    }

    &-crown {
        background-image: url('~assets/icons/ic_crown.svg');
    }

    &-stripe {
        background-image: url('~assets/icons/ic_stripe.svg');
    }

    &-done {
        background-image: url('~assets/icons/ic_done.svg');
    }

    &-success {
        background-image: url('~assets/icons/ic_success.svg');
    }

    &-chevronOrange {
        background-image: url('~assets/icons/ic_chevronOrange.svg');
    }

    &-warning {
        background-image: url('~assets/icons/ic_warning.svg');
    }

    &-info {
        background-image: url('~assets/icons/ic_info.svg');
    }

    &-successCircle {
        background-image: url('~assets/icons/ic_success_circle.svg');
    }

    &-picked {
        background-image: url('~assets/icons/ic_picked.svg');
    }

    &-chevron {
        background-image: url('~assets/icons/ic_chevron.svg');
    }
}
