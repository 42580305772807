@font-face {
    font-family: 'SF Pro Display';
    src: url('#{$font-path}SFProDisplay-Bold.woff2') format('woff2'),
        url('#{$font-path}SFProDisplay-Bold.woff') format('woff'),
        url('#{$font-path}SFProDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('#{$font-path}SFProDisplay-Heavy.woff2') format('woff2'),
        url('#{$font-path}SFProDisplay-Heavy.woff') format('woff'),
        url('#{$font-path}SFProDisplay-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('#{$font-path}SFProDisplay-Medium.woff2') format('woff2'),
        url('#{$font-path}SFProDisplay-Medium.woff') format('woff'),
        url('#{$font-path}SFProDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('#{$font-path}SFProDisplay-Regular.woff2') format('woff2'),
        url('#{$font-path}SFProDisplay-Regular.woff') format('woff'),
        url('#{$font-path}SFProDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('#{$font-path}SFProDisplay-Semibold.woff2') format('woff2'),
        url('#{$font-path}SFProDisplay-Semibold.woff') format('woff'),
        url('#{$font-path}SFProDisplay-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

