.p-paymentResult {
    max-width: rem(560);
    margin-left: auto;
    margin-right: auto;
    padding: rem(130) rem(32);
    background-color: $white;
    border-radius: rem(28);

    &_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &_image {
        max-width: rem(250);
        margin-left: auto;
        margin-right: auto;
        margin-bottom: rem(32);
    }

    &_title {
        margin-top: rem(8);
        margin-bottom: rem(8);
    }

    &_button {
        width: rem(342);
        border-radius: rem(26);
        margin-left: auto;
        margin-right: auto;
        margin-top: rem(32);
    }
}
