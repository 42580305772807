.o-promoCodeInput {
    border-radius: rem(16);
    border-width: 1px;
    border-top-width: rem(6);
    border-style: solid;
    border-color: $gainsboro;
    padding: rem(16);
    @include adjust-flex(space-between);

    &_information {
        flex-grow: 1;
    }

    &_button {
        margin-left: rem(24);
    }
  
    &_input {
        .tfcInput {
            background-color: transparent;
            padding: 0;

            input {
                padding: 0;
                @include font-base(16, 20);
                font-weight: 500;
                height: rem(23);
                margin-top: rem(4);
                background-color: transparent;

                &::placeholder {
                    color: $ash-grey;
                }
            }
        }

        &_errMessage {
            @include font-base(12, 18);
            color: $fire-engine-red;
            margin-top: rem(4);
            display: block;
        }
    }

    &_button {
        .tfcbtn {
            background-color: transparent;
        }
    }

    &_btnDisabled {
        background-color: $warm-white-smoke !important;
        border-radius: rem(8);
        cursor: not-allowed;

        .tfcText {
            color: $eerie-black-06;
        }

        &:hover {
            background-color: #f8f8f9 !important;
        }
    }

    &_btnDisabled2 {
        background-color: $platinum !important;
        border-radius: rem(8);
        cursor: not-allowed;

        .tfcText {
            color: #55535b;
        }
    }
}
