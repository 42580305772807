.p-payment {
    $root: &;

    &_top {
        background-color: $white;
        padding: rem(32);
        border-radius: rem(28);

        @include mobile-down {
            padding: rem(16);
        }
    }

    &_title {
        font-weight: 700;
    }

    &_description {
        margin-top: rem(16);
        color: $sonic-silver;
        font-weight: 400;
    }

    &_plan {
        background-color: #fdccb2;
        padding: rem(16) rem(24);
        border-radius: rem(16);
        margin-top: rem(32);

        @include mobile-down {
            padding: rem(16);
        }

        &_limit {
            margin-top: rem(4);
        }

        &_detail {
            background-color: $seashell;
            padding: rem(16) rem(24);
            border-radius: rem(16);

            @include mobile-down {
                padding: rem(16);
            }
        }

        &_item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: rem(4);
        }

        &_description {
            margin-top: rem(16);
            margin-left: rem(16);
        }
    }

    &_options {
        background-color: $warm-white-smoke;
        padding: rem(32);
        border-radius: rem(28);
        margin-top: rem(16);

        @include mobile-down {
            padding: rem(16);
        }
    }

    &_radio {
        height: rem(80);
        border: 1px solid $gainsboro;
        border-radius: rem(16);
        padding: rem(16) rem(24);
        display: block;
        margin-top: rem(16);
        transition: all $transition;
        overflow: hidden;

        &:first-child {
            margin-top: 0;
        }

        .tfcRadio-holder {
            display: none;
        }

        &-active {
            #{$root} {
                &_option {
                    &_holder {
                        border-width: rem(5);
                        border-color: $deep-carrot-orange;
                    }
    
                }

                &_info {
                    border-top: 1px dashed #d5d4d8;
                }
            }
        }

        &-expand {
            height: rem(288);
        }
    }

    &_option {
        display: flex;
        align-items: center;
        width: 100%;

        &_holder {
            width: rem(18);
            height: rem(18);
            border: 1px solid $arsenic;
            border-radius: rem(9);
            flex-shrink: 0;
        }

        &_name {
            margin-left: rem(8);
            flex-grow: 1;
        }

        .a-icon {
            width: rem(48);
            height: rem(48);
            flex-shrink: 0;
        }
    }

    &_info {
        padding-top: rem(32);
    }

    &_input {
        background-color: $white;
        height: rem(72);
        border-radius: rem(12);
        padding: rem(12) rem(16);
        width: calc(50% - rem(8));
        margin-top: rem(16);

        &:first-child {
            width: 100%;
        }

        &:nth-child(2) {
            margin-right: rem(8);
        }

        &:nth-child(3) {
            margin-left: rem(8);
        }

        .tfcInput {
            padding: 0;

            input {
                padding: 0;
                @include font-base(15, 22); 
                font-weight: 500;
                height: rem(23);
                margin-top: rem(4);
            }
        }
    }

    &_promo {
        margin-top: rem(16);

        &_content {
            background-color: $white;
            display: flex;
            border-radius: rem(12);
            overflow: hidden;
        }

        &_input {
            padding: rem(12) rem(16);
            flex-grow: 1;

            .tfcInput {
                padding: 0;
    
                input {
                    padding: 0;
                    @include font-base(15, 22); 
                    font-weight: 500;
                    height: rem(23);
                    margin-top: rem(4);
                }
            }
        }

        &_button {
            button {
                height: 100%;
                width: rem(80);
                color: $deep-carrot-orange;
                position: relative;
                background-color: $white;
                transition: $transition;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 0;
                    height: 100%;
                    background-color: $deep-carrot-orange;
                    z-index: 1;
                    transition: $transition;
                }

                .tfcText {
                    position: relative;
                    z-index: 2;
                    @include font-base(16, 24);
                    font-weight: 600; 
                }

                &:hover {
                    color: $white;

                    &::before {
                        width: 100%;
                    }
                }
            }
        }

    }

    &_divider {
        border-bottom: 1px dashed $lavender-gray;
        margin-top: rem(32);
        margin-bottom: rem(16);
    }

    &_confirm {
        padding: rem(16) rem(24);
        background-color: rgba($white, 0.6);
        border-radius: rem(16);
        margin-top: rem(16);

        @include mobile-down {
            padding: rem(8) rem(12);
        }

        &_button {
            background-color: $lime-green;
            height: rem(48);
            flex-direction: row;
            border-radius: rem(8);
            width: 100%;
            margin-top: rem(12);

            &:hover {
                background-color: $forest-green;
            }

            .a-icon {
                width: rem(24);
                height: rem(24);
                margin-right: rem(8);
                flex-shrink: 0;
            }
        }
    }

    &_totalText,
    &_finalPrice {
        margin-left: rem(4);
    }

    &_modalBtn {
        max-width: rem(200);
        margin: auto;
    }
}
